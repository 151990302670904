const processEnv = process.env

export const env: { [key: string]: any } = Object.keys(processEnv).reduce((acc: {}, key: string) => {
  if (key.startsWith('REACT_APP_')) {
    return {
      ...acc,
      [key.replace(/^REACT_APP_/, '')]: processEnv[key],
    }
  }
  return acc
}, { dev: process.env.NODE_ENV === 'development' })
