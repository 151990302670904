import React from 'react'
import {
  Container, Col, Row,
} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from 'react-router-dom'

const QRPage: React.FC = () => (
  <Container className="my-5">
    <Row>
      <Col>
        <ol>
          <li className="py-2">
            <Link to="/">URL content-based QR code</Link>
          </li>
          <li className="py-2">
            <Link to="/uuid">UUID content-based QR code</Link>
          </li>
          <li className="py-2">
            <Link to="/char">Alphanumeric content-based QR code</Link>
          </li>
        </ol>
      </Col>
    </Row>
  </Container>
)

export default QRPage
