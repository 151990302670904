import { onSnapshot } from 'mobx-state-tree'
import { RootStoreModel, RootStore } from './root-store'
import { Environment } from './environment'
import { env } from '../utils'

const STORAGE_KEY = 'mst-root'

export async function createEnvironment() {
  const environment = new Environment()
  await environment.setup()
  return environment
}

export const setupRootStore = async () => {
  let rootStore: RootStore
  let data: any

  const environment = await createEnvironment()

  try {
    data = JSON.parse(localStorage.getItem(STORAGE_KEY))
    rootStore = RootStoreModel.create(data, environment)
  } catch (error) {
    rootStore = RootStoreModel.create({}, environment)
  }

  if (env.dev) {
    environment.reactotron.setRootStore(rootStore, data)
  }

  onSnapshot(rootStore, snapshot => {
    // NOTE beware of browser storage limit: 2MB limitation on android 4.3 and 10MB on chrome 40
    localStorage.setItem(STORAGE_KEY, JSON.stringify(snapshot))
  })

  return rootStore
}
