import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import MTRLogo from '../../assets/logo.png'
import './header.css'

export const Header: React.FC = () => {
  return (
    <Navbar collapseOnSelect bg="light" expand className="header">
      <Container>
        <Navbar.Brand>
          <img
            src={MTRLogo}
            alt="MTR"
            className="brand-logo"
            aria-hidden="true"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      </Container>
    </Navbar>
  )
}
