import React from 'react'
import { Container, Col, Row } from 'react-bootstrap'
import QRCode from 'qrcode.react'
import locationData from '../../sampleData/location.json'

const SamplePage: React.FC = () => {
  const locationEntries: Array<LocationEntry> = locationData
  return (
    <Container>
      <Row>
        {
          locationEntries.map(data => (
            <Col xs={4} key={data.id} className="text-center py-5">
              <QRCode
                value={data.id}
              />
              <br />
              {data.name}
            </Col>
          ))
        }
      </Row>
    </Container>

  )
}

export default SamplePage
