import React, { useEffect, useState } from 'react'
import QrReader from 'react-qr-reader'
import { Placeholder } from 'semantic-ui-react'

interface QRCameraProps {
  onError: (err: any) => void
  onScan: (data?: string) => void
  onLoad: () => void
  isRefresh: boolean
  setIsRefresh: any
}

const QRCamera: React.FC<QRCameraProps> = ({
  onError,
  onScan,
  onLoad,
  isRefresh,
  setIsRefresh,
}) => {
  const [isStarted, setIsStarted] = useState(true)

  const onFocus = () => {
    setIsStarted(true)
  }
  const onBlur = () => {
    setIsStarted(false)
  }

  useEffect(() => {
    if (isRefresh) {
      setIsRefresh(false)
      setIsStarted(false)
    } else {
      setIsStarted(true)
    }
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  })

  return isStarted ? (
    <QrReader
      facingMode="environment"
      delay={500}
      onError={onError}
      onScan={onScan}
      onLoad={onLoad}
      style={{ width: '100%' }}
      showViewFinder={false}
      aria-hidden="true"
    />
  ) : (
    <Placeholder style={{ width: '100%' }}>
      <Placeholder.Image square />
    </Placeholder>
  )
}

export { QRCamera }
