export interface ReactotronConfig {
  /** The name of the app. */
  name?: string
  /** The host to connect to: default 'localhost'. */
  host?: string
  /** Should we clear Reactotron when load? */
  clearOnLoad?: boolean
  /** Root state logging. */
  state?: {
    /** log the initial data that we put into the state on startup? */
    initial?: boolean
    /** log snapshot changes. */
    snapshots?: boolean
  }
}

/**
 * The default Reactotron configuration.
 */
export const DEFAULT_REACTOTRON_CONFIG: ReactotronConfig = {
  clearOnLoad: true,
  // TODO: Support IE9, THIS LINE NEEDS TO BE CHANGED TO
  // an exact ip to by pass the IE security measures and
  // run dev mode successfully.
  host: 'localhost',
  // ===========================================================
  //
  state: {
    initial: true,
    snapshots: false,
  },
}
