import 'bootstrap/dist/css/bootstrap.min.css'
import { observer } from 'mobx-react'
import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { Button, Container, Grid, Icon, Message } from 'semantic-ui-react'
import { Footer, QRCamera } from '../../components'
import { Header } from '../../components/Header'
import { useStores } from '../../models/root-store'
import { navigationNS } from '../../utils/i18n'
import './home-page.css'

interface RouterProps {
  id: string
}

interface HomePageProps extends RouteComponentProps<RouterProps> {}

const HomePage: React.FC<HomePageProps> = observer(({ match }) => {
  const { t, i18n } = useTranslation()
  const {
    userStore: { didReadWelcome: isStarted, readWelcome: setIsStarted },
  } = useStores()

  const [cameraPermissionGranted, setCameraPermissionGranted] = useState(false)

  const [hideIntro, setHideIntro] = useState(isStarted)
  const [cameraLoaded, setCameraLoaded] = useState(false)
  const [navigationId, setNavigationId] = useState('')

  const idKey = `${navigationNS}:${navigationId}.name`
  const message = i18n.exists(idKey) ? t(idKey) : t('homePage.scanQRcodeMsg')

  useEffect(() => {
    if (!hideIntro) return
    navigator.getUserMedia(
      { video: true },
      () => {
        setCameraPermissionGranted(true)
      },
      error => {
        console.warn('permission not granted', error)
      },
    )
  }, [hideIntro])

  useEffect(() => {
    const { id } = match.params
    setNavigationId(id)
  }, [match.params])

  const handleScan = React.useCallback(
    (data?: string) => {
      if (data) {
        try {
          const url = new URL(data)
          if (
            url.origin.toLowerCase() === window.location.origin.toLowerCase()
          ) {
            const params = url.href.split('/')
            if (params.length > 0) {
              const navId = params[params.length - 1]
              if (navId === 'intro') {
                setHideIntro(false)
              } else {
                if (!i18n.exists(`${navigationNS}:${navId}.name`)) return
                setNavigationId(navId)
              }
            }
          }
        } catch (err) {
          console.tron.reportError(err)
        }
      }
    },
    [i18n],
  )

  const handleRefresh = React.useCallback(() => {
    setIsRefresh(true)
    setNavigationId('')
  }, [])

  const [isRefresh, setIsRefresh] = React.useState<boolean>(false)
  const handleError = React.useCallback((err: any) => console.error(err), [])

  const handleLoaded = React.useCallback(() => setCameraLoaded(true), [])

  const startScanning = React.useCallback(() => {
    setIsStarted()
    setHideIntro(true)
  }, [setIsStarted])

  const landing = React.useMemo(
    () => (
      <Container className="App my-5">
        <Message size="large" info content={t('homePage.welcomeMessage')} />
        <Button
          id="btn-start"
          size="big"
          icon
          primary
          labelPosition="right"
          onClick={startScanning}
          role="dialog"
          aria-live="polite"
          aria-labelledby={t('homePage.startTitle')}
        >
          {t('homePage.startTitle')}
          <Icon name="arrow right" />
        </Button>
        <Message
          icon="universal access"
          aria-hidden="true"
          content={t('homePage.alternateMessage')}
        />
      </Container>
    ),
    [startScanning, t],
  )

  const qrCodeScanner = React.useMemo(
    () => (
      <Grid.Column
        xs={12}
        md={8}
        lg={6}
        aria-hidden="true"
        className="justify-content-center"
      >
        <QRCamera
          onError={handleError}
          onScan={handleScan}
          onLoad={handleLoaded}
          isRefresh={isRefresh}
          setIsRefresh={setIsRefresh}
        />
      </Grid.Column>
    ),
    [handleError, handleLoaded, handleScan, isRefresh, setIsRefresh],
  )

  return (
    <div className="w-100" id="container">
      <Header />
      {!hideIntro ? (
        landing
      ) : cameraPermissionGranted ? (
        <Container className="App my-5">
          <Grid column={3}>
            <Grid.Column stretched>
              <Grid.Column>
                {qrCodeScanner}
                <Grid.Row
                  xs={12}
                  md={8}
                  lg={6}
                  className="justify-content-center"
                >
                  <Fragment key="location">
                    {cameraLoaded && (
                      <span
                        className="h5"
                        aria-live="polite"
                        aria-labelledby={message}
                      >
                        {message}
                      </span>
                    )}
                  </Fragment>
                </Grid.Row>
              </Grid.Column>
              <Button
                id="btn-refresh"
                size="small"
                color="blue"
                onClick={handleRefresh}
                aria-live="polite"
                aria-labelledby={t('homePage.refreshMessage')}
              >
                {t('homePage.refreshMessage')}
              </Button>
            </Grid.Column>
          </Grid>
        </Container>
      ) : (
        <Container className="App my-5">
          <Message size="large" info content={t('homePage.grantPermission')} />
        </Container>
      )}

      <Footer />
    </div>
  )
})

export default HomePage
