import { types, Instance, SnapshotOut } from 'mobx-state-tree'
import i18next from 'i18next'

export const UserStoreModel = types
  .model('UserStore')
  .props({
    navigationId: types.maybeNull(types.string),
    didReadWelcome: types.optional(types.boolean, false),
  })
  .actions(self => ({
    setLanguage(language: string) {
      // i18next detector cache the language
      i18next.changeLanguage(language)
    },
    setNavigationId(id: string) {
      self.navigationId = id
    },
    readWelcome() {
      self.didReadWelcome = true
    },
  }))

type UserStoreType = Instance<typeof UserStoreModel>
export interface UserStore extends UserStoreType {}
type UserStoreSnapshotType = SnapshotOut<typeof UserStoreModel>
export interface UserStoreSnapshot extends UserStoreSnapshotType {}
