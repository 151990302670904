import React from 'react'
import { Navbar } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { Button, Container } from 'semantic-ui-react'
import { useStores } from '../../models/root-store'
import { resourcesNS, supportLanguages } from '../../utils/i18n'
import './footer.css'

export const Footer: React.FunctionComponent = () => {
  const { i18n } = useTranslation()
  const { userStore } = useStores()

  return (
    <Navbar collapseOnSelect bg="light" id="footer">
      <Container id="footer-container">
        <p />
        <Button.Group id="language-button-group" floated="right">
          {supportLanguages.map(language => (
            <Button
              key={language}
              color={i18n.language.includes(language) ? 'blue' : null}
              onClick={() => userStore.setLanguage(language)}
            >
              {i18n.getResource(language, resourcesNS, 'languageName')}
            </Button>
          ))}
        </Button.Group>
      </Container>
    </Navbar>
  )
}
