import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { resources } from './resources'

export default i18next
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },

    fallbackLng: 'zh-Hant',
  })

export const supportLanguages = Object.keys(resources)
