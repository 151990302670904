import { Reactotron } from '../utils'
import { Api, ApiConfig } from '../api'

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    // create each service
    this.reactotron = new Reactotron()
    this.api = new Api()
  }

  async setup() {
    // allow each service to setup
    await this.reactotron.setup()
  }

  setupApi(config: ApiConfig) {
    this.api.setup(config)
  }

  /**
   * Reactotron is only available in dev.
   */
  reactotron?: Reactotron

  /**
   * Our api.
   */
  api: Api
}
