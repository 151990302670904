import { ApiResponse, ApisauceInstance, create } from 'apisauce'
import Axios, { CancelTokenSource } from 'axios'
import { env } from '../utils'
import { ApiConfig } from './api-config'
import { ApiError, getGeneralApiProblem } from './api-problem'

export class Api {
  apisauce: ApisauceInstance

  config: ApiConfig

  cancelToken?: CancelTokenSource

  /**
   *
   * Sets up the API.  This will be called during the bootup
   * sequence.
   *
   * This will also cancel all ongoing requests of previous
   * api object, if any
   *
   */
  setup(config?: ApiConfig) {
    if (this.cancelToken != null) {
      this.cancelToken.cancel()
    }

    if (config != null) {
      this.config = config

      console.tron.display({
        name: 'API Update',
        preview: config.baseUrl,
        value: config,
      })
    }

    this.cancelToken = Axios.CancelToken.source()

    // construct the apisauce instance
    this.apisauce = create({
      baseURL: this.config.baseUrl,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
        'Api-Key': this.config.apiKey,
      },
      cancelToken: this.cancelToken.token,
    })

    if (env.dev) {
      this.apisauce.addMonitor(console.tron.apisauce)
    }
  }

  cancelAllRequests() {
    // setup again the apisauce object to trigger cancel handling
    this.setup()
  }

  /**
   *
   * General API wrapper by transforming apisauce promise into
   * target payload type T or api problem
   *
   * @param T The type of the API payload body
   * @param apiPromise apisauce promise for the request
   *
   */
  async generalApi<T>(apiPromise: Promise<ApiResponse<T>>): Promise<{ response: ApiResponse<T>, payload: T }> {
    let response: ApiResponse<T>
    try {
      response = await apiPromise
    } catch (err) {
      console.tron.logImportant('API Failed', err)
      throw new ApiError({ kind: 'unknown' })
    }

    if (!response || !response.ok) {
      const problem = getGeneralApiProblem(response)
      if (problem) throw new ApiError(problem)
    }

    try {
      const data = response.data as T
      return { response, payload: data }
    } catch (err) {
      console.tron.log('Failed in parsing response', response)
      throw new ApiError({ kind: 'bad-data', sourceError: err })
    }
  }

  getIndex = async () =>
    this.generalApi<any>(
      this.apisauce.get('/')
    )
}
