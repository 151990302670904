import en from './languages/en.json'
import zhHk from './languages/zhHk.json'
import enData from '../../sampleData/location-en.json'
import zhHkData from '../../sampleData/location-zhHk.json'

export const resourcesNS = 'translation'
export const navigationNS = 'navigation'
export const resources = {
  en: {
    [resourcesNS]: en,
    [navigationNS]: enData,
  },
  'zh-Hant': {
    [resourcesNS]: zhHk,
    [navigationNS]: zhHkData,
  },
}
