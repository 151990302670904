import { Provider } from 'mobx-react'
import React from 'react'
import { RootStore, RootStoreProvider } from './models/root-store'
import { setupRootStore } from './models/setup-root-store'
import AppRouter from './router'

export default class App extends React.Component<{}, { rootStore: RootStore }> {
  constructor(props) {
    super(props)
    this.state = { rootStore: undefined }
  }

  async componentDidMount() {
    this.setState({
      rootStore: await setupRootStore(),
    })
  }

  render() {
    const { rootStore } = this.state

    if (rootStore == null) return null

    const { ...stores } = rootStore

    return (
      <RootStoreProvider value={rootStore}>
        <Provider rootStore={rootStore} {...stores}>
          <AppRouter />
        </Provider>
      </RootStoreProvider>
    )
  }
}
