import React from 'react'
import { HashRouter, Route } from 'react-router-dom'
import HomePage from './pages/home/home-page'
import QRPage from './pages/qr/qr-page'
import SamplePage from './pages/samples/sample-page'

function AppRouter() {
  return (
    <HashRouter>
      <Route path="/samples" component={SamplePage} exact />
      <Route path="/" component={HomePage} exact />
      <Route path="/:id" component={HomePage} />
      <Route path="/mode" component={QRPage} exact />
    </HashRouter>
  )
}

export default AppRouter
