import { types, Instance, SnapshotOut } from 'mobx-state-tree'
import { withEnvironment } from '../extensions/with-environment'
import { UserStoreModel } from '../user-store'

export const RootStoreModel = types
  .model('RootStore')
  .props({
    userStore: types.optional(UserStoreModel, {}),
  })
  .extend(withEnvironment)

export type RootStore = Instance<typeof RootStoreModel>
export type RootStoreSnapshot = SnapshotOut<typeof RootStoreModel>
